<template>
    <div class="page page-about">
        <div class="about-content">
            <div class="item">
                <div class="title">Position: Director of Business Development and Operation</div>
                <div class="info">
                    <p><b>Responsibilities:</b></p>
                    <p style="color: #ccc;">
                        1. Responsible for overall online business planning, demand analysis, integrated product functional design and planning with company's development strategy, testing the product and launching it online;<br /><br />
                        2. Responsible for coordinating with the company's internal and business partners in  project management, to ensure the implementation of product  upgrades, application promotion, product maintenance and other links;<br /><br />
                        3. Responsible for the optimization and integration of the company's "To B+To C" diversified business in the Mainland and Hong Kong, responsible for the company's brand business quality and product risk control, and enhance the company's industry influence;<br /><br />
                        4. Lead the team to build a user community platform, regularly collect, sort, and analyze operational data, gain in-depth understanding of target market needs and user habits in Hong Kong and the Mainland;<br /><br />
                        5. Responsible for the promotion of new products and the expansion of cooperation channels, the planning and implementation of operations and promotion activities in various regions and the completion of indicators, and maintaining long-term partnerships with partners;<br /><br />
                        6. Understand the cutting-edge trends of the game and blockchain industry in the Mainland, Hong Kong and other Asian regions, and coordinate the department to continuously track and analyze competing products, promote product improvements and adjust changes in marketing strategies;<br /><br />
                        7. Responsible for the establishment of management and performance appraisal of business operation personnel, formulate a complete training mechanism for the department, and rapidly grow and train an excellent operation team.<br /><br />
                    </p>
                    <p><b>Requirements:</b></p>
                    <p style="color: #ccc;">
                        1. Bachelor degree or above, at least 5 years of Internet product operation and blockchain technology and other related industry experience, and more than 2 years of management experience;<br /><br />
                        2. Abundant data analysis, operation, user management, brand promotion and resource integration capabilities, familiar with mainland market policies and regulations, user needs and community operation experience is preferred;<br /><br />
                        3. Possess website and APP operation experience, familiar with the whole process of product operation;<br /><br />
                        4. Possess rich project management skills, strong team management skills, communication skills and crisis management skills;<br /><br />
                        5. Adapt to high-intensity work, willing to travel to the Mainland and overseas, fluency in Mandarin is preferred.<br /><br />
                    </p>
                </div>
            </div>
        </div>
<!--        <div class="about-form">-->
<!--            <div class="title">Get In Touch</div>-->
<!--            <div class="tips">-->
<!--                Want to keep updated on our latest blockchain games? <br />-->
<!--                Stay connected!-->
<!--            </div>-->
<!--            <div class="form">-->
<!--                <div class="item">-->
<!--                    <label>Name</label>-->
<!--                    <input />-->
<!--                </div>-->
<!--                <div class="item">-->
<!--                    <label>Your email address</label>-->
<!--                    <input />-->
<!--                </div>-->
<!--                <div class="button">-->
<!--                    <button>Subscribe</button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>

<style lang="less">
@import "../../less/main.less";
.page-about {
    .about-content {
        width: 600px;
        margin: 0 auto;
        @media @mobile {
            width: 100%;
        }
        .item {
            padding: 36px;
            .title {
                padding-bottom: 36px;
                font-size: 24px;
                text-align: center;
                font-family: GothamBold;
            }
            .info {
                text-align: center;
                font-size: 18px;
                line-height: 28px;
                h2, p {
                    text-align: left;
                }
            }
        }
    }
    .about-form {
        width: 100%;
        background-color: #0F0F0F;
        margin: 0 auto;
        padding: 48px 0;
        .title {
            text-align: center;
            font-size: 24px;
        }
        .tips {
            text-align: center;
            padding: 24px 0;
        }
        .form {
            width: 300px;
            margin: 0 auto;
            .item {
                border-bottom: solid 1px #3F3F3F;
                display: flex;
                margin-bottom: 18px;
                label {
                    color: #353535;
                    padding-right: 12px;
                }
                input {
                    background-color: transparent;
                    flex: 1;
                    color: #fff;
                    &:focus {
                        border: none!important;
                        outline: none!important;
                    }
                }
            }
            .button {
                display: flex;
                padding: 24px 0;
                justify-content: center;
                button {
                    background-color: #008900;
                    padding: 3px 24px;
                    font-size: 16px;
                    color: #fff;
                    border-radius: 5px;
                }
            }
        }
    }
}
</style>
